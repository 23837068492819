exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-background-generator-js": () => import("./../../../src/pages/background-generator.js" /* webpackChunkName: "component---src-pages-background-generator-js" */),
  "component---src-pages-border-radius-generator-js": () => import("./../../../src/pages/border-radius-generator.js" /* webpackChunkName: "component---src-pages-border-radius-generator-js" */),
  "component---src-pages-box-shadow-generator-js": () => import("./../../../src/pages/box-shadow-generator.js" /* webpackChunkName: "component---src-pages-box-shadow-generator-js" */),
  "component---src-pages-button-generator-js": () => import("./../../../src/pages/button-generator.js" /* webpackChunkName: "component---src-pages-button-generator-js" */),
  "component---src-pages-en-background-generator-js": () => import("./../../../src/pages/en/background-generator.js" /* webpackChunkName: "component---src-pages-en-background-generator-js" */),
  "component---src-pages-en-border-radius-generator-js": () => import("./../../../src/pages/en/border-radius-generator.js" /* webpackChunkName: "component---src-pages-en-border-radius-generator-js" */),
  "component---src-pages-en-box-shadow-generator-js": () => import("./../../../src/pages/en/box-shadow-generator.js" /* webpackChunkName: "component---src-pages-en-box-shadow-generator-js" */),
  "component---src-pages-en-button-generator-js": () => import("./../../../src/pages/en/button-generator.js" /* webpackChunkName: "component---src-pages-en-button-generator-js" */),
  "component---src-pages-en-flexbox-generator-js": () => import("./../../../src/pages/en/flexbox-generator.js" /* webpackChunkName: "component---src-pages-en-flexbox-generator-js" */),
  "component---src-pages-en-gradient-generator-js": () => import("./../../../src/pages/en/gradient-generator.js" /* webpackChunkName: "component---src-pages-en-gradient-generator-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-text-shadow-generator-js": () => import("./../../../src/pages/en/text-shadow-generator.js" /* webpackChunkName: "component---src-pages-en-text-shadow-generator-js" */),
  "component---src-pages-en-transform-generator-js": () => import("./../../../src/pages/en/transform-generator.js" /* webpackChunkName: "component---src-pages-en-transform-generator-js" */),
  "component---src-pages-en-transition-generator-js": () => import("./../../../src/pages/en/transition-generator.js" /* webpackChunkName: "component---src-pages-en-transition-generator-js" */),
  "component---src-pages-flexbox-generator-js": () => import("./../../../src/pages/flexbox-generator.js" /* webpackChunkName: "component---src-pages-flexbox-generator-js" */),
  "component---src-pages-gradient-generator-js": () => import("./../../../src/pages/gradient-generator.js" /* webpackChunkName: "component---src-pages-gradient-generator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-selektory-css-js": () => import("./../../../src/pages/selektory-css.js" /* webpackChunkName: "component---src-pages-selektory-css-js" */),
  "component---src-pages-text-shadow-generator-js": () => import("./../../../src/pages/text-shadow-generator.js" /* webpackChunkName: "component---src-pages-text-shadow-generator-js" */),
  "component---src-pages-transform-generator-js": () => import("./../../../src/pages/transform-generator.js" /* webpackChunkName: "component---src-pages-transform-generator-js" */),
  "component---src-pages-transition-generator-js": () => import("./../../../src/pages/transition-generator.js" /* webpackChunkName: "component---src-pages-transition-generator-js" */),
  "component---src-pages-wlasciwosci-css-js": () => import("./../../../src/pages/wlasciwosci-css.js" /* webpackChunkName: "component---src-pages-wlasciwosci-css-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-css-properties-template-js": () => import("./../../../src/templates/css-properties-template.js" /* webpackChunkName: "component---src-templates-css-properties-template-js" */)
}

